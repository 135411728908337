
import { defineComponent } from "vue";
// eslint-disable-next-line
// @ts-ignore
import attributions from "../../oss-attribution/attributions.json";

export default defineComponent({
  setup() {
    const attributionList = Object.keys(attributions).map((groupName) => {
      return attributions[groupName];
    });
    return { attributionList };
  },
});
